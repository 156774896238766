import { animated, config, useSpring, useSprings, useTransition } from 'react-spring'
import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'
import clamp from 'lodash/clamp'
import { useGesture } from '@use-gesture/react'
import { useLocationMatch } from '@kaliber/routing'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { refToDataXLink, trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { useTranslate } from '/machinery/I18n'
import { useResizeHandler } from '/machinery/useResizeHandler'
import { routeMap } from '/routeMap'
import { UppercaseHeadingPlainMd } from '/features/buildingBlocks/UppercaseHeading'
import { SubheadingItalicMd } from '/features/buildingBlocks/Subheading'
import { HeadingXs } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import { BlurredImageMask } from '/features/buildingBlocks/BlurredImageMask'
import { TextXs } from '/features/buildingBlocks/Text'
import { VisuallyHidden } from '/features/accessibility/VisuallyHidden'
import { ButtonGhost, ButtonRoundedIconSlider } from '/features/buildingBlocks/Button'
import { VideoCover } from '/features/buildingBlocks/Video'
import { Cursor } from '/features/buildingBlocks/Cursor'

import iconChevronRight from '/images/icons/chevron-right-boxed.raw.svg'
import iconChevronLeft from '/images/icons/chevron-left-boxed.raw.svg'

import cssStyleContext from '/cssGlobal/style-context.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './CareerSlider.css'

const gap = 16

export function CareerSlider({ careerSlider, layoutClassName = undefined }) {
  const { heading, subHeading } = careerSlider ?? {}
  const slides = careerSlider.slides || []
  const [activeIndex, setActiveIndex] = React.useState(Math.floor(slides.length / 2))
  const isMounted = useRenderOnMount()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {isMounted && (
        <>
          {slides.length && (
            <Slider
              onIndexChange={setActiveIndex}
              layoutClassName={styles.sliderLayout}
              {...{ slides, activeIndex }}
            />
          )}

          <div className={styles.textContainer}>
            {heading && (<UppercaseHeadingPlainMd h='2' title={heading} />)}
            {subHeading && (<SubheadingItalicMd title={subHeading} />)}
          </div>

          <div role="tablist" className={styles.dashContainerElement}>
            {slides?.map((_, i) => (
              <Dash key={i} index={i} onClick={handleDashClick} {...{ activeIndex }} />
            ))}
          </div>
        </>
      )}

    </div>
  )

  function handleDashClick(x) {
    setActiveIndex(x)
  }
}

function Dash({ index, activeIndex, onClick }) {
  const { __ } = useTranslate()

  return (
    <div
      key={index}
      aria-selected={activeIndex === index}
      className={styles.componentDash}
      onClick={handleClick}
      role="tab"
    >
      <button
        aria-label={__({ x: index + 1 })`dash-x`}
        data-x={`slide-to-${index + 1}`}
        className={cx(styles.dashElement, activeIndex === index && styles.isActive)}
      >
        <VisuallyHidden>
          {__({ x: index + 1 })`dash-x`}{`click-to-go-to-slide-${index + 1}}`}
        </VisuallyHidden>
      </button>
    </div>
  )

  function handleClick() {
    onClick(index)
  }
}

function Slider({ slides, activeIndex, onIndexChange, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  const { cardWidth, cardHeight } = useGetCardSizes()
  const { springs, isDragging, bind, api } = useCardSwipeGesture({
    onChange: onIndexChange,
    items: slides ?? [],
    activeIndex,
    cardWidth,
    cardHeight
  })

  useResizeHandler(resizeHandler)
  resizeHandler()

  return (
    <div className={cx(styles.componentSlider, layoutClassName)}>
      <ButtonRoundedIconSlider
        onClick={handlePrevious}
        ariaLabel={__({ x: __`card` })`previous-x`}
        icon={iconChevronLeft}
        dataX={`slide-to-${previousIndex({ activeIndex, slides }) + 1}`}
        layoutClassName={styles.arrowLeftLayout}
      />

      {springs.map(({ x, y, scale, display, rotate }, i) => (
        <animated.div
          key={i}
          style={{ display, rotate, y, x }}
          className={cx(styles.card, isDragging && styles.isDragging)}
          // @ts-ignore
          {...bind(i)}
        >
          <Slide slide={slides[i]} {...{ i, scale, activeIndex, cardWidth, cardHeight }} />
        </animated.div>
      ))}

      <ButtonRoundedIconSlider
        onClick={handleNext}
        ariaLabel={__({ x: __`card` })`next-x`}
        icon={iconChevronRight}
        dataX={`slide-to-${nextIndex({ activeIndex, slides }) + 1}`}
        layoutClassName={styles.arrowRightLayout}
      />
    </div>
  )

  function resizeHandler() {
    api.start(i => calculateCardTransform({ i, active: false, mx: 0, items: slides, cardWidth, cardHeight, activeIndex }))
  }

  function handleNext() {
    onIndexChange(nextIndex({ activeIndex, slides }))
  }

  function handlePrevious() {
    onIndexChange(previousIndex({ activeIndex, slides }))
  }
}

function Slide({ slide, i, scale, activeIndex, cardWidth, cardHeight }) {
  const [isPlaying, setIsPlaying] = React.useState(false)

  const isCurrent = i === activeIndex
  const hasContent = slide.title || slide.description || slide.button?.ref

  const transition = useTransition(isCurrent, {
    from: { y: 25, opacity: 0 },
    enter: { y: isCurrent ? 0 : 25, opacity: isCurrent ? 1 : 0, delay: 150 },
    leave: { y: 25, opacity: 0 }
  })

  const { gradientTransition } = useSpring({
    gradientTransition: (hasContent && isCurrent) ? '35%' : '0%',
    config: { ...config.molasses, duration: 250 }
  })

  const { blurTransition } = useSpring({
    blurTransition: (hasContent && isCurrent) ? '1' : '0',
    config: { ...config.molasses, duration: 250 }
  })

  const aspectRatio = useNearestAspectRatio({
    width: cardWidth,
    height: cardHeight
  })

  const isVideoPlayer = slide.toggle === 'videoPlayer' && slide.videoPlayer
  const isBackgroundVideo = slide.toggle === 'video' && slide.video
  const isImage = slide.toggle === 'image' && slide.image?.asset

  return (
    <animated.div
      style={{ scale, '--gradient-transition-value': gradientTransition }}
      className={cx(styles.componentSlide, isCurrent && styles.isActive)}
    >
      {transition(style => (
        <animated.div
          className={styles.text}
          data-style-context={cssStyleContext.contextBlack}
          {...{ style }}
        >
          {slide.title && <HeadingXs h='3' title={slide.title} />}
          {slide.description && <TextXs text={slide.description} />}
          {slide.button?.ref && (
            <ButtonGhost
              dataX={refToDataXLink(slide.button.ref)}
              layoutClassName={styles.buttonLayout}
              href={determineDocumentPathSyncWithParams({ document: slide.button.ref, routeMap, params: slide.button.params })}
            >
              {slide.button.label}
            </ButtonGhost>
          )}
        </animated.div>
      ))}

      {isVideoPlayer && !isPlaying && (
        <button
          className={styles.cursor}
          data-x='click-to-play-video'
          onClick={() => setIsPlaying(!isPlaying)}
        >
          <Cursor layoutClassName={styles.cursorLayout} />
        </button>
      )}

      {isImage && aspectRatio && (
        <div className={styles.imageWrapper}>
          <ImageCover
            image={slide.image}
            imgProps={{ draggable: false }}
            layoutClassName={styles.imageLayout}
            {...{ aspectRatio }}
          />

          <animated.div
            style={{ opacity: blurTransition }}
            className={styles.blurredOverlay}
          >
            <BlurredImageMask
              image={slide.image}
              layoutClassName={styles.blurredOverlayLayout}
            />
          </animated.div>
        </div>
      )}

      {isBackgroundVideo && aspectRatio && (
        <VideoBackground
          video={slide.video}
          onPlayChange={setIsPlaying}
          layoutClassName={styles.imageLayout}
          {...{ isPlaying, isCurrent, aspectRatio }}
        />
      )}

      {isVideoPlayer && aspectRatio && (
        <VideoPlayerCard
          video={slide.videoPlayer}
          onPlayChange={setIsPlaying}
          layoutClassName={styles.imageLayout}
          {...{ isPlaying, isCurrent, aspectRatio, cardWidth }}
        />
      )}
    </animated.div>
  )
}

function VideoPlayerCard({ video, isPlaying, isCurrent, aspectRatio, onPlayChange, layoutClassName, cardWidth }) {
  const { title, url, poster } = video

  React.useEffect(
    () => { if (!isCurrent) onPlayChange(false) },
    [isCurrent, onPlayChange]
  )

  return (
    <VideoCover
      loop={false}
      muted={false}
      autoPlay={false}
      posterSizes={cardWidth}
      {...{ isPlaying, onPlayChange, url, title, aspectRatio, poster, layoutClassName }}
    />
  )
}

function VideoBackground({ video, isPlaying, isCurrent, aspectRatio, onPlayChange, layoutClassName }) {
  const { title, url, poster } = video

  React.useEffect(
    () => { onPlayChange(isCurrent) },
    [isCurrent, onPlayChange]
  )

  return (
    <VideoCover {...{ isPlaying, onPlayChange, url, title, aspectRatio, poster, layoutClassName }} />
  )
}

function useCardSwipeGesture({ activeIndex, items, onChange, cardWidth, cardHeight }) {
  const [isDragging, setIsDragging] = React.useState(false)
  const [springs, api] = useSprings(items.length, (i) => ({
    x: i * cardWidth,
    y: 0,
    scale: 1,
    rotate: 0,
    display: 'block'
  }))

  React.useEffect(
    () => { api.start((i) => calculateCardTransform({ i, items, cardWidth, cardHeight, activeIndex })) },
    [activeIndex]
  )

  const bind = useGesture({
    onDragEnd: handleDragEnd,
    onDragStart: handleDragStart,
    onDrag: ({ active, movement: [mx], direction: [xDir], cancel }) => {
      if (active && Math.abs(mx) > cardWidth / 2) {
        const clampedValue = clamp(activeIndex + (xDir > 0 ? -1 : 1), 0, items.length - 1)

        onChange(clampedValue)
        cancel()
      }

      api.start((i) => calculateCardTransform({ i, active, mx, items, cardWidth, cardHeight, gap, activeIndex }))
    }
  })

  return {
    bind,
    springs,
    api,
    isDragging
  }

  function handleDragStart(e) {
    setIsDragging(true)
    const [index] = e.args
    trackInteraction({
      title: 'career-slider',
      action: 'dragged',
      type: 'slider',
      index
    })
  }

  function handleDragEnd() {
    setIsDragging(false)
  }
}

function useNearestAspectRatio({ width, height }) {
  const [aspectRatio, setAspectRatio] = React.useState(null)

  React.useEffect(
    () => setAspectRatio(getRatio()),
    []
  )

  return aspectRatio

  function getRatio() {
    const commonDivisor = greatestCommonDivisor(width, height)
    const aspectWidth = width / commonDivisor
    const aspectHeight = height / commonDivisor

    return aspectWidth / aspectHeight
  }
}

function greatestCommonDivisor(a, b) {
  return b === 0 ? a : greatestCommonDivisor(b, a % b)
}

function getRelativePositionFromCenter({ items, item, activeIndex }) {
  const itemIndex = items.indexOf(item)
  return itemIndex !== -1
    ? itemIndex - activeIndex
    : null
}

function calculateCardTransform({ i, active = false, mx = 0, items, cardWidth, cardHeight, activeIndex }) {
  const position = getRelativePositionFromCenter({ items, item: items[i], activeIndex })
  const absolutePosition = Math.abs(position)

  const halfOfScreen = window.innerWidth / 2
  const halfOfCard = cardWidth / 2
  const offset = (i - activeIndex)
  const size = (cardWidth + gap)

  const x = halfOfScreen - halfOfCard + offset * size + (active ? mx : 0)
  const scale =
    active
      ? 1 - Math.abs(mx) / cardWidth / 20
      : 1

  const y = absolutePosition * (absolutePosition * (cardHeight * 0.05))
  const rotate = position * 5

  return { x, y, scale, rotate, display: 'block' }
}

function useGetCardSizes() {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const cardWidth = isViewportMd ? parseFloat(styles.cardWidthMd) : parseFloat(styles.cardWidthSm)
  const cardHeight = isViewportMd ? parseFloat(styles.cardHeightMd) : parseFloat(styles.cardHeightSm)

  return { cardWidth, cardHeight }
}

function nextIndex({ activeIndex, slides }) {
  return activeIndex >= (slides.length - 1) ? 0 : activeIndex + 1
}

function previousIndex({ activeIndex, slides }) {
  return activeIndex <= 0 ? (slides.length - 1) : activeIndex - 1
}
