export function useResizeHandler(callback) {
  const debounceTimeoutRef = React.useRef(null)
  const prevWindowWidthRef = React.useRef(null)
  const prev100vhRef = React.useRef(null)

  React.useEffect(() => {
    prevWindowWidthRef.current = window.innerWidth
    prev100vhRef.current = get100vhPx()
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      clearTimeout(debounceTimeoutRef.current)
    }

    function handleResizeEnd() {
      // when you scroll down and then back up on mobile devices, the address bar
      // appears / disappears, causing a resize event we don't want to react to
      if (resizeIsCausedByBrowserUI()) {
        return
      }
      callback()
    }

    function handleResize() {
      clearTimeout(debounceTimeoutRef.current)
      debounceTimeoutRef.current = setTimeout(handleResizeEnd, 50)
    }
  }, [callback])

  function resizeIsCausedByBrowserUI() {
    const windowWidth = window.innerWidth
    if (prevWindowWidthRef.current  === windowWidth && heightHasNotChanged()) {
      return true
    }
    prevWindowWidthRef.current = windowWidth
    return false
  }

  function heightHasNotChanged() {
    // on mobile, 100 vh remains is the same in mobile windows both with and without address bar and menu bar
    const vh100 = get100vhPx()
    if (prev100vhRef.current === vh100) {
      return true
    }
    prev100vhRef.current = vh100
    return false
  }
}

function get100vhPx() {
  const tempDiv = document.createElement('div')
  Object.assign(tempDiv.style, {
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
    visibility: 'hidden',
  })
  document.body.appendChild(tempDiv)

  const heightPx = tempDiv.clientHeight
  tempDiv.remove()

  return heightPx
}
